import React, { memo } from 'react';

import cn from 'classnames'
import Image from 'next/image';

import { CopyBtn, Tooltip } from '@element/index';
import helper from '@utils/helper';
import { infoParse } from '@utils/projects/infoParse'

import { chainIconMap } from '../constants/chainIcon'

import styles from './index.module.less';

const defaultIcon = require('@images/projects/defaultChainIcon.webp');

function ChainIcon (props) {
  const { chainInfo, isStack, size = 16, maxCountIcon = 3, isShowAll } = props;
  const { infoList } = infoParse(chainInfo);
  const selectList = isShowAll ? infoList : (infoList || []).slice(0, maxCountIcon);

  return (
    <div className={styles.container}>
      {selectList.map((item) => {
        const { address, name } = item;
        const namePath = (name || '').toUpperCase();
        const path = chainIconMap?.[namePath] || defaultIcon;
        if (!path) return null;
        return (
          <div className={cn(styles.networkIcon, styles.zIndex3,
            { [styles.stacking]: isStack })} key={address}>
            {address ? <Tooltip
              color="#434B5F"
              align={{ offset: [0, 0] }}
              overlayInnerStyle={{ padding: '8px' }}
              title={
                <span className={styles.tooltipsTitle}>
                  {helper.formatLongText(address, 8, -6)}
                  <CopyBtn
                    text={address}
                    iconClassName={styles.iconClassName}
                  />
                </span>
              }>
              <span className={cn(styles.hoverColor, 'DINPro')}>
                <Image
                  src={path}
                  width={size}
                  height={size}
                  layout='fixed'
                  alt="kikitrade"
                />
              </span>
            </Tooltip>
              : <span className={cn(styles.hoverColor, 'DINPro')}>
                <Image
                  src={path}
                  width={size}
                  height={size}
                  layout='fixed'
                  alt="kikitrade"
                />
              </span>}
          </div>
        )
      })}
    </div>
  );
}

export default memo(ChainIcon);
